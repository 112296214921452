<template>
  <div class="gallery">
    <div class="images">
      <img :src="images[currentImg]" class="gallery-img" />
    </div>
    <div class="change-imgs" v-if="images.length > 1">
      <p @click="previousImg()" class="prev"></p>
      <p class="num-imgs">{{ currentImg + 1 }} / {{ images.length }}</p>
      <p @click="nextImg()" class="next"></p>
    </div>
  </div>
</template>


<script>
export default {
  props: ["images"],
  data() {
    return {
      currentImg: 0
    };
  },
  methods: {
    nextImg() {
      if (this.currentImg < this.images.length - 1) {
        this.currentImg++;
      } else if (this.currentImg >= this.images.length - 1) {
        this.currentImg = 0;
      }
      return this.currentImg;
    },
    previousImg() {
      if (this.currentImg == 0) {
        let i = this.images.length;
        this.currentImg = i - 1;
      } else {
        this.currentImg--;
      }
    },
  },
};
</script>

<style  scoped lang="scss">
@import "./scss/media/general/gallery.scss";
@import "./scss/media/1440/gallery.scss";
@import "./scss/media/850/gallery.scss";
@import "./scss/media/550/gallery.scss";
@import "./scss/media/380/gallery.scss";
</style>