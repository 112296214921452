<template>
  <div id="component">
    <header>
      <nav>
        <router-link :to="{ name: 'Home' }"> <img src="../assets/back.svg" alt="" /> {{ __("back") }} </router-link>
      </nav>
      <img class="logo" src="../assets/logo.svg" />
    </header>
    <div id="page" v-if="vehicle">
      <div class="vehicle-details">
        <div class="model">{{ vehicle.model_description }}<small>{{ __("vehicle_details_vehicle_identifier") }}: {{vehicle.production_no}}</small></div>
        <div class="engine" v-if="vehicle.power_kw > 0">
          {{ __("engine_output") }}<b>{{ vehicle.power_kw }} kW</b>
        </div>
        <div class="cylinder" v-if="vehicle.engine_ccm">
          {{ __("cylinder_capacity") }}<b>{{ vehicle.engine_ccm }} ccm</b>
        </div>
        <div class="view-toggle">
          <div class="overview" :class="{ active: currentScreen == SCREEN_OVERVIEW }">
            <input
              type="radio"
              v-model="currentScreen"
              :value="SCREEN_OVERVIEW"
              :id="'screen_switch_' + SCREEN_OVERVIEW"
            />
            <label :for="'screen_switch_' + SCREEN_OVERVIEW">{{ __("overview") }}</label>
            <div class="sub-toggles">
              <div class="sub-toggle" v-if="vehicle.exterior_images && vehicle.exterior_images.length > 0">
                <input
                  type="radio"
                  v-model="currentSubscreen"
                  :value="SUBSCREEN_360"
                  :id="'ss_switch' + SUBSCREEN_360"
                />
                <label :for="'ss_switch' + SUBSCREEN_360">{{ __("360_View") }} </label>
              </div>
              <div class="sub-toggle" v-if="vehicle.custom_images && vehicle.custom_images.length > 0">
                <input
                  type="radio"
                  v-model="currentSubscreen"
                  :value="SUBSCREEN_GALLERY"
                  :id="'ss_switch' + SUBSCREEN_GALLERY"
                />
                <label :for="'ss_switch' + SUBSCREEN_GALLERY">{{ __("gallery") }}</label>
              </div>
              <div class="sub-toggle" v-if="vehicle.video_link && vehicle.video_link.length > 0">
                <input
                  type="radio"
                  v-model="currentSubscreen"
                  :value="SUBSCREEN_VIDEO"
                  :id="'ss_switch' + SUBSCREEN_VIDEO"
                />
                <label :for="'ss_switch' + SUBSCREEN_VIDEO">{{ __("video") }}</label>
              </div>
            </div>
          </div>
          <div
            v-if="vehicle.equipment && vehicle.equipment.length > 0"
            class="packages"
            :class="{ active: currentScreen == SCREEN_PACKAGES }"
          >
            <input
              type="radio"
              v-model="currentScreen"
              :value="SCREEN_PACKAGES"
              :id="'screen_switch_' + SCREEN_PACKAGES"
            />
            <label :for="'screen_switch_' + SCREEN_PACKAGES">{{ __("equipment") }}</label>
            <div class="sub-toggles">
              <div
                v-for="p in packages"
                class="sub-toggle"
                :key="'package_' + p.code"
                :class="{
                  active: selectedPackage && p.code == selectedPackage.code,
                }"
              >
                <input type="radio" v-model="selectedPackage" :value="p" :id="'ss_switch_package' + p.code" />
                <label :for="'ss_switch_package' + p.code">{{ p.name }}</label>
              </div>
              <div class="sub-toggle">
                <input type="radio" v-model="selectedPackage" :value="otherEquipment" :id="'ss_switch_package_other'" />
                <label :for="'ss_switch_package_other'">Other equipment</label>
              </div>
            </div>
          </div>
          <div
            class="accessories"
            :class="{ active: currentScreen == SCREEN_ACCESSORIES }"
            v-if="vehicle.accessories && vehicle.accessories.length > 0"
          >
            <input
              type="radio"
              v-model="currentScreen"
              :value="SCREEN_ACCESSORIES"
              :id="'screen_switch_' + SCREEN_ACCESSORIES"
            />
            <label :for="'screen_switch_' + SCREEN_ACCESSORIES"> {{ __("accessories") }} </label>
          </div>
        </div>
      </div>

      <div class="center-screen">
        <div class="terms-popup" v-if="terms != null">
          <div>{{ terms }}</div>
          <button @click="terms = null" class="close-btn">{{ __("close") }}</button>
        </div>
        <FinancialCalculator
          :total-price="vehicle.retail_price_private"
          v-if="currentScreen == SCREEN_CALCULATOR"
          @close="currentScreen = SCREEN_OVERVIEW"
          :financialCalculator.sync="financialCalculator"
        />
        <template v-else-if="currentScreen == SCREEN_OVERVIEW">
          <Gallery :images="vehicle.custom_images" v-if="currentSubscreen == SUBSCREEN_GALLERY" />
          <Video :video="vehicle.video_link" v-else-if="currentSubscreen == SUBSCREEN_VIDEO" />
          <ThreeSixtyView :images="vehicle.exterior_images" v-else />
        </template>
        <Accessories
          :accessories="vehicle.accessories"
          :image="vehicle.cockpit_image"
          v-else-if="currentScreen == SCREEN_ACCESSORIES"
        />
        <Package
          class="package-screen"
          v-else-if="currentScreen == SCREEN_PACKAGES"
          :pkg="selectedPackage"
          :image="vehicle.cockpit_image"
        />
      </div>

      <div class="financing-info">
        <Book @close="showForm = false" v-if="showForm" @terms="showTerms" />
        <template v-else>
          <div class="financing-info-top">
            <div class="price-info">
              <div class="total-price">
                <h3>{{ __("total_price") }}</h3>
                <p>
                  {{ vehicle.retail_price_private.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
                  {{ vehicle.currency }}
                </p>
              </div>
              <p class="price-txt">
                {{ vehicle.store_free_text }}
              </p>
              <hr class="line" />
            </div>
            <div class="financial-calculator" @click="currentScreen = SCREEN_CALCULATOR">
              <p class="financing">
                {{ __("financing_from") }}<br />
                <span class="price">
                  <b
                    >{{ monthlyPrice }} {{ vehicle.currency }} /
                    {{ __("monthly") }}
                  </b>
                </span>
              </p>
              <div class="financial-services-toggle">
                <span> {{ __("financial_services_calculator") }}</span>
              </div>
            </div>
            <div class="spacer">&nbsp;</div>
          </div>
          <div class="financing-info-bottom" v-if="vehicle.dealer">
            <p>{{ vehicle.dealer.name }}</p>
            <p>
              <a :href="'tel:'+vehicle.dealer.phone">{{ vehicle.dealer.phone }} </a>
            </p>
            <p>
              <a :href="'mailto:'+vehicle.dealer.email">{{ vehicle.dealer.email }}</a>
            </p>
            <button class="btn" @click="toggleForm()">
              {{ __("request_offer") }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import ThreeSixtyView from "../components/ThreeSixtyView.vue";
import FinancialCalculator from "../components/FinancialCalculator.vue";
import Video from "../components/Video.vue";
import Book from "../components/Book.vue";
import Accessories from "../components/Accessories.vue";
import Package from "../components/Package.vue";
import Gallery from "../components/Gallery.vue";
import FinancialCalculatorFactory from "../services/FinancialCalculatorFactory";
import axios from "axios";
export default {
  components: {
    ThreeSixtyView,
    FinancialCalculator,
    Video,
    Book,
    Accessories,
    Package,
    Gallery,
  },
  name: "BikeDetails",
  data() {
    return {
      SCREEN_CALCULATOR: "calculator",
      SCREEN_OVERVIEW: "overview",
      SCREEN_ACCESSORIES: "accessories",
      SCREEN_PACKAGES: "packages",
      SUBSCREEN_360: "360",
      SUBSCREEN_GALLERY: "gallery",
      SUBSCREEN_VIDEO: "video",
      currentScreen: null,
      currentSubscreen: null,
      vehicle: null,
      showForm: false,
      selectedPackage: null,
      terms: null,
      financialCalculator: FinancialCalculatorFactory.get("se"),
    };
  },
  computed: {
    packages() {
      if (this.vehicle && this.vehicle.equipment) {
        return this.vehicle.equipment.filter((val) => {
          return val.type == "PACKAGE";
        });
      } else {
        return [];
      }
    },
    otherEquipment() {
      return {
        code: "other",
        name: "Other equipment",
        type: "PACKAGE",
        children: this.vehicle.equipment.filter((val) => {
          return val.type != "PACKAGE";
        }),
      };
    },
    monthlyPrice() {
      return Math.round(this.financialCalculator.loanValue())
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  mounted() {
    this.fetchData();
    this.currentScreen = this.SCREEN_OVERVIEW;
    this.currentSubscreen = this.SUBSCREEN_360;
  },
  methods: {
    fetchData() {
      axios.get("/api/vehicles/" + this.$route.params.id).then((response) => {
        this.vehicle = response.data;
        this.financialCalculator.setParameters(
          this.vehicle.retail_price_private,
          this.financialCalculator.defaultDownpayment(),
          this.financialCalculator.defaultFinancingTime(),
          this.financialCalculator.defaultRemainingValue()
        );
        if (this.packages.length > 0) {
          this.selectedPackage = this.packages[0];
        }
        if (window.sendSize) {
          console.log("Sending vehicle size");
          setTimeout(() => {
            console.log("Sending vehicle size");
            window.sendSize("imageLoad", "Image loaded");
          }, 5000);
        }
      });
    },
    showTerms(text) {
      this.terms = text;
    },
    toggleForm() {
      this.showForm = true;
      this.currentScreen = this.SCREEN_OVERVIEW;
      this.$nextTick(() => {
        setTimeout(() => {
          window.sendSize("imageLoad", "Image loaded");
        }, 10);
      });
    },
  },
};
</script>

<style  scoped lang="scss">
#component::v-deep {
  @import "./scss/media/general/bikedetails.scss";
  @import "./scss/media/1440/bikedetails.scss";
  @import "./scss/media/1280/bikedetails.scss";
  @import "./scss/media/1024/bikedetails.scss";
  @import "./scss/media/850/bikedetails.scss";
  @import "./scss/media/550/bikedetails.scss";
  @import "./scss/media/380/bikedetails.scss";
}
</style>