class FinancialCalculator {

    setParameters(totalPrice, downPayment, rentalTime, remainingValue, distancePerYear) {
        this.totalPrice = totalPrice;
        this.downPayment = downPayment;
        this.rentalTime = rentalTime;
        this.remainingValue = remainingValue;
    }

    replaceInText(text) {
        text = text.replaceAll(":interest-rate", this.termInterestRate());
        text = text.replaceAll(":effective-interest-rate", Math.round(this.effectiveInterestRate()*10000)/100);
        text = text.replaceAll(":min-downpayment", this.minDownpayment());
        return text;
    }

    financingTimes() {
        return [12, 24, 36, 48, 60, 72];
    }

    defaultFinancingTime() {
        return 36;
    }

    hasRemainingValueOption() {
        return true;
    }

    defaultRemainingValue() {
        return 50;
    }

    maxRemainingValue() {
        let max = 0;
        let range = Math.abs(this.rentalTime);
        switch (range) {
            case 12:
                max = 75;
                break;
            case 24:
                max = 60;
                break;
            case 36:
                max = 50;
                break;
            case 48:
                max = 40;
                break;
            case 60:
                max = 20;
                break;
        }
        return Math.min(100 - this.downPayment, max);
    }

    minRemainingValue() {
        return 0;
    }

    defaultDownpayment() {
        return 20;
    }

    minDownpayment() {
        return 20;
    }

    maxDownpayment() {
        return 90;
    }

    startupFee() {
        return 595;
    }

    loanValue() {
        return 55 + this.termMonthlyInstalment();
    }

    termFinancedAmount() {
        return this.totalPrice * (100 - Math.round(this.downPayment)) / 100;
    }

    termInterestRate() {
        return 4.45;
    }

    termRemainingValue() {
        return Math.round(this.totalPrice * (this.remainingValue / 100));
    }

    termMonthlyInstalment() {
        return this.pmt(this.termInterestRate() / 1200, this.rentalTime, -this.termFinancedAmount(), this.termRemainingValue(), 0);
    }

    financedAmount() {
        return this.totalPrice - (this.downPayment * this.totalPrice / 100);
    }

    effectiveInterestRate() {
        return this.effect(this.rate(this.rentalTime, this.loanValue(), -(this.financedAmount() - this.startupFee()), this.termRemainingValue(), 0) * 12, 12);
    }

    totalCostDuringTerm() {
        return Math.round(this.rentalTime * Math.round(this.loanValue()) + this.startupFee() + this.termRemainingValue());
    }


    pmt(rate_per_period, number_of_payments, present_value, future_value, type) {
        if (rate_per_period != 0.0) {
            // Interest rate exists
            let q = Math.pow(1 + rate_per_period, number_of_payments);
            return -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));

        } else if (number_of_payments != 0.0) {
            // No interest rate, but number of payments exists
            return -(future_value + present_value) / number_of_payments;
        }

        return 0;
    }

    rate(periods, payment, present, future, type, guess) {
        guess = (guess === undefined) ? 0.01 : guess;
        future = (future === undefined) ? 0 : future;
        type = (type === undefined) ? 0 : type;
        let epsMax = 1e-10;
        let iterMax = 10;
        let y, y0, y1, x0, x1 = 0,
            f = 0,
            i = 0;
        let rate = guess;
        if (Math.abs(rate) < epsMax) {
            y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
        } else {
            f = Math.exp(periods * Math.log(1 + rate));
            y = present * f + payment * (1 / rate + type) * (f - 1) + future;
        }
        y0 = present + payment * periods + future;
        y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
        i = x0 = 0;
        x1 = rate;
        while ((Math.abs(y0 - y1) > epsMax) && (i < iterMax)) {
            rate = (y1 * x0 - y0 * x1) / (y1 - y0);
            x0 = x1;
            x1 = rate;
            if (Math.abs(rate) < epsMax) {
                y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
            } else {
                f = Math.exp(periods * Math.log(1 + rate));
                y = present * f + payment * (1 / rate + type) * (f - 1) + future;
            }
            y0 = y1;
            y1 = y;
            ++i;
        }
        return rate;
    }

    effect(rate, periods) {
        if (rate <= 0 || periods < 1) {
            return 0;
        }
        periods = parseInt(periods, 10);
        return Math.pow(1 + rate / periods, periods) - 1;
    }
}

export default FinancialCalculator;