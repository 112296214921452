import FinancialCalculator from "./FinancialCalculator/FinancialCalculator";

class FinancialCalculatorFactory {
    static get(locale) {
        switch (locale) {
            default:
                return new FinancialCalculator();
        }
    }

    static getOperative(locale) {
        switch (locale) {
            default:
                return new FinancialCalculator();
        }
    }
}

export default FinancialCalculatorFactory;