<template>
  <div>
    <div class="package">
      <h2>{{ pkg.name }}</h2>
      <ul class="options">
        <li class="packages-list" v-for="item in pkg.children" :key="item.id">• {{ item.name }}</li>
      </ul>
    </div>
    <div class="package-img" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
  </div>
</template>


<script>
export default {
  props: ["pkg", "image"],
};
</script>

