<template>
  <div class="form">
    <p
      :class="{
        'is-invalid-p':
          $v.form.phone.$error || $v.form.first_name.$error || $v.form.last_name.$error || $v.form.email.$error,
      }"
    >
      {{ __("fields") }}
    </p>
    <button @click="close()" class="close-btn">{{ __("close") }}</button>
    <form @submit.prevent="submitForm" class="registration">
      <div class="inputs">
        <div :class="{ 'is-invalid': $v.form.first_name.$error }" class="form-group">
          <input type="text" v-model="$v.form.first_name.$model" id="firstname" :placeholder="__('first_name')" />
          <label for="firstname"
            ><span> {{ __("first_name") }}</span></label
          >
        </div>
        <div :class="{ 'is-invalid': $v.form.last_name.$error }" class="form-group">
          <input type="text" v-model="$v.form.last_name.$model" id="lastname" :placeholder="__('last_name')" />
          <label for="lastname"
            ><span> {{ __("last_name") }}</span></label
          >
        </div>
        <div :class="{ 'is-invalid': $v.form.phone.$error }" class="form-group">
          <input type="text" v-model="$v.form.phone.$model" id="phone" :placeholder="__('phone')" />
          <label for="phone"
            ><span> {{ __("phone") }}</span></label
          >
        </div>
        <div :class="{ 'is-invalid': $v.form.email.$error }" class="form-group">
          <input type="email" v-model="$v.form.email.$model" id="email" :placeholder="__('email')" />
          <label for="email"
            ><span> {{ __("email") }}</span></label
          >
        </div>
      </div>
      <div class="spacer"></div>
      <div class="terms">
        <p class="marketing">{{ __("marketing") }}</p>
        <div v-for="term in terms" :key="term.id">
          <input type="checkbox" :id="'terms_' + term.id" v-model="form.terms" :value="term.id" />
          <label :for="'terms_' + term.id"
            >{{ term.description }}
            <img :key="term.img" src="../assets/info.svg" alt="" @click.prevent="termsinfo(term.info)" />
          </label>
        </div>

        <p class="txt">
          {{ __("policy") }}
          <img src="../assets/info.svg" style="margin-left: 5px" alt="" @click="termsinfo(policy)" />
        </p>
      </div>
      <button class="btn" type="submit">{{ __("request_offer") }}</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
  props: ["term"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        terms: [],
      },
      submitted: false,
      serverValidation: {},
      marketing: "Samtycke till mottagande av marknadsföring. ",
      policy:
        "Jag kan när som helst återkalla de samtycken jag lämnat till BMW Northern Europe AB genom att kontakta BMW:s kundtjänst 0200-22 82 00 eller nordic.dataprivacy@bmw.se. Vid fullständigt återkallande kommer min kund-profil inte längre att behandlas och jag kommer inte längre att få marknadsföringsutskick. Via de ovan angivna kommunikationskanalerna kan jag också begära information om mina uppgifter som lagras av BMW Northern Europe AB och även begära rättning, radering eller begränsning av behandling av mina personuppgifter. Ytterligare information angående BMW Northern Europe AB:s behandling av mina personuppgifter och mina rättigheter finns att tillgå online i BMW:s privacy policy. ",
      terms: [
        {
          id: 1,
          name: "jag",
          description:
            " Jag vill ta emot marknadsföring från BMW via e-post, post, telefon/meddelandetjänster och i bilen (om sådana tjänster stöds). ",
          info:
            "Jag samtycker till att BMW Northern Europe AB (org.nr. 556313-5002, postadress: Box 794, 191 27, Sollentuna) behandlar personuppgifter som jag lämnat (t.ex. kontaktuppgifter och annan personlig information, såsom födelsedatum och typ av körkort samt hobbys och intressen) för kundvårdsändamål och för riktad marknadsföring av produkter och tjänster från BMW-koncernen samt för marknadsundersökningar. BMW Northern Europe AB får också, för ovan nämnda ändamål, överföra dessa uppgifter till vissa närstående bolag liksom till vissa avtalsparter och verkstäder (den återförsäljare jag uppgivit att jag föredrar, som jag köpt ett fordon av eller varit på service- eller rådgivningsbesök hos eller den närmast belägna återförsäljare som kan hjälpa mig). Uppdateringar av sådan information får delas mellan de närstående bolagen. ",
        },
        {
          id: 2,
          name: "jag",
          description:
            " Jag vill även få erbjudanden från BMW Group om produkter och tjänster särskilt utvalda på grundval av mina personliga preferenser och på min användning av produkter och tjänster. ",
          info:
            "Jag samtycker till att mina personuppgifter behandlas för att skapa en individuell kundprofil genom statistisk analys. Tack vare denna kundprofil kommer jag få marknadsföring med innehåll anpassat för mig, såsom t.ex. personliga erbjudanden från BMW Northern Europe AB och vissa närstående bolag samt vissa avtalsparter och verkstäder (den återförsäljare jag uppgivit att jag föredrar, som jag köpt ett fordon av eller varit på service- eller rådgivningsbesök hos eller den närmast belägna återförsäljare som kan hjälpa mig). Jag samtycker också till att kundprofilen överförs från BMW Northern Europe AB till vissa närstående bolag samt till mina avtalsparter och verkstäder för de ovan nämnda ändamålen. I förekommande fall kan följande uppgifter som jag tillhandahåller eller som skapas genom min användning av BMW Northern Europe AB:s, vissa närstående bolags och deras avtalsparters samt verkstäders produkter eller tjänster komma att behandlas för att skapa kundprofilen: kontaktuppgifter (t.ex. namn, adress, e-postadress), tilläggsinformation/preferenser (t.ex. föredragen återförsäljare, hobbys), identifikationsuppgifter (t.ex. kundnummer, avtalsnummer), kundhistorik (t.ex. mottagna erbjudanden, uppgifter om fordonsköp, uppgifter om återförsäljare), fordonsdata (t.ex. användardata i appen ”BMW Connected”: mätarställning, sträcka till tom tank), data från användning av appar, hemsidor eller sociala medier. En uttömmande lista med uppgifter från dessa kategorier hittar du här. ",
        },
      ],
      showInf: true,
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        serverError(value) {
          return !this.serverValidation["first_name"];
        },
      },
      last_name: {
        required,
        serverError(value) {
          return !this.serverValidation["last_name"];
        },
      },
      phone: {
        required,
        serverError(value) {
          return !this.serverValidation["phone"];
        },
      },
      email: {
        required,
        email,
        serverError(value) {
          return !this.serverValidation["email"];
        },
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    termsinfo(terms) {
      this.$emit("terms", terms);
    },
    validationStatus(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submitForm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      axios
        .post("/api/rfo/" + this.$route.params.id, this.form)
        .then((response) => {
          if (response.data && response.data.success) {
            this.$router.push({ name: "Confirm" });
          } else {
            this.serverValidation = response.data.errors;
          }
        })
        .catch((reason) => {
          if (reason && reason.response && reason.response.data) {
            this.serverValidation = reason.response.data.errors;
          }
        });
    },
  },
};
</script>


<style scoped lang="scss">
@import "./scss/media/general/book.scss";
@import "./scss/media/1440/book.scss";
@import "./scss/media/1280/book.scss";
@import "./scss/media/1024/book.scss";
@import "./scss/media/850/book.scss";
@import "./scss/media/550/book.scss";
</style>