<template>
  <div id="video">
    <iframe
      v-if="videoID"
      width="560"
      height="315"
      :src="'https://www.youtube.com/embed/' + videoID + '?controls=0'"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>


<script>
export default {
  props: ["video"],
  computed: {
    videoID() {
      if (this.video.split("v=").length > 0) {
        var videoID = this.video.split("v=")[1];
        var ampersandPosition = videoID.indexOf("&");
        if (ampersandPosition != -1) {
          videoID = videoID.substring(0, ampersandPosition);
        }
        return videoID;
      }
      return null;
    },
  },
};
</script>


<style lang="scss" scoped>
#video {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  padding-bottom: 60%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 95%;
  }
}
@media screen and (max-width: 650px) {
  #video {
    padding-bottom: 105%;
  }
}
@media screen and (max-width: 650px) {
  #video {
    padding-bottom: 130%;
  }
}
</style>