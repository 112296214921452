<template>
  <div class="images-container stage" ref="stage">
    <template v-if="loaded">
      <div class="images">
        <div v-for="(image, index) in images" class="img" :class="{ current: currentImage === index }" :key="image.id">
          <img :src="image" alt="" draggable="false" />
        </div>
      </div>
      <div class="indicator" :class="{ hidden: hasPanned }"></div>
    </template>
    <div class="images static" v-else>
      <div class="img current">
        <img :src="images[startImage]" alt="" draggable="false" />
      </div>
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";
export default {
  props: ["images"],
  created() {
    this.init();
  },
  mounted() {
    let stage = this.$refs.stage;
    let mc = new Hammer.Manager(stage);
    let pan = new Hammer.Pan();

    mc.add(pan);
    mc.on("panstart", (e) => {
      if (this.loaded) {
        this.startImage = this.currentImage;
        this.hasPanned = true;
        let doc = document.documentElement;
        this.currentScroll.left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
        this.currentScroll.top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      }
    });
    mc.on("pan", (e) => {
      if (this.loaded) {
        if (Math.abs(e.velocityX) < Math.abs(e.velocityY)) {
          window.scrollTo(this.currentScroll.left, this.currentScroll.top - e.deltaY);
        } else {
          if (e.deltaX < 0) {
            this.currentImage = Math.max(0, (this.startImage - Math.round((e.deltaX / 10) % 36)) % 36);
          } else {
            this.currentImage = Math.max(
              0,
              (this.startImage + (this.imageCount - 1 - Math.round((e.deltaX / 10) % 36))) % 36
            );
          }
        }
      }
    });
    mc.on("panend", (e) => {
      if (this.loaded) {
        this.startImage = this.currentImage;
      }
    });
  },
  methods: {
    init() {
      let initialImg = new Image();
      initialImg.src = this.images[this.startImage];
      let loadedNum = 0;
      for (let i = 0; i < this.images.length; i++) {
        let img = new Image();
        img.onload = () => {
          loadedNum++;
          if (loadedNum >= this.imageCount) {
            this.loaded = true;
          }
        };
        img.src = this.images[i];
      }
    },
  },
  data() {
    return {
      currentImage: 0,
      imageCount: 36,
      imagesLoaded: false,
      startImage: 0,
      timeout: null,
      interval: null,
      loaded: false,
      hasPanned: false,
      currentScroll: {
        top: 0,
        left: 0,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "./scss/media/general/360.scss";
@import "./scss/media/1440/360.scss";
@import "./scss/media/1280/360.scss";
@import "./scss/media/1024/360.scss";
@import "./scss/media/850/360.scss";
</style>