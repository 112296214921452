<template>
  <div>
    <div class="acessories-list">
      <ul>
        <li v-for="accessorie in accessories" :key="accessorie.id">
          <span class="name"> {{ accessorie.name }} </span>
          <span class="price"> {{ accessorie.price }} {{__('currency')}}</span>
        </li>
      </ul>
    </div>
    <img class="motorcycle" :src="image" alt="" />
  </div>
</template>

<script>
export default {
  props:["accessories", "image"]
};
</script>
<style  scoped lang="scss">
@import "./scss/media/general/accessories.scss";
@import "./scss/media/1440/accessories.scss";
@import "./scss/media/1280/accessories.scss";
@import "./scss/media/1024/accessories.scss";
@import "./scss/media/850/accessories.scss";
@import "./scss/media/550/accessories.scss";
</style>