<template>
  <div class="financial-cal">
    <button @click="close()" class="close-btn">{{ __("close") }}</button>
    <div class="sliders">
      <div class="slider">
        <div class="slider-labels">
          <div class="min">{{ display.downpaymentMin }} {{ __("currency") }}</div>
          <div class="current">
            {{ __("downpayment") }}<span class="value"> {{ display.downpayment }} {{ __("currency") }} </span>
          </div>
          <div class="max">{{ display.downpaymentMax }} {{ __("currency") }}</div>
        </div>
        <vue-slider
          ref="slider"
          v-model="downpayment"
          :dot-size="16"
          :height="2"
          :min="financialCalculator.minDownpayment()"
          :max="financialCalculator.maxDownpayment()"
          :interval="1"
          :tooltip="false"
        ></vue-slider>
      </div>
      <div class="slider">
        <div class="slider-labels">
          <div class="min">{{ display.periodMin }}</div>
          <div class="current">
            {{ __("period") }} <span class="value"> {{ display.period }} </span>
          </div>
          <div class="max">{{ display.periodMax }}</div>
        </div>
        <vue-slider
          ref="slider"
          v-model="period"
          :dot-size="16"
          :height="2"
          :data="financialCalculator.financingTimes()"
          :tooltip="false"
        ></vue-slider>
      </div>
      <div class="slider">
        <div class="slider-labels">
          <div class="min">{{ display.remainingMin }} {{ __("currency") }}</div>
          <div class="current">
            {{ __("remaining") }} <span class="value"> {{ display.remaining }} {{ __("currency") }}</span>
          </div>
          <div class="max">{{ display.remainingMax }} {{ __("currency") }}</div>
        </div>
        <vue-slider
          ref="slider"
          v-model="remaining"
          :dot-size="16"
          :height="2"
          :min="financialCalculator.minRemainingValue()"
          :max="financialCalculator.maxRemainingValue()"
          :interval="1"
          :tooltip="false"
        ></vue-slider>
      </div>
      <div class="monthly">
        <div>
          {{ __("monthly_payment") }}
        </div>
        <b style="margin-left: 30px"> {{ display.estimatedMonthly }} {{ __("currency") }} </b>
      </div>
    </div>
    <div class="text">
      <p>
        {{ __("financial_calc_text") }}
      </p>
      <p>
        {{ financialCalculator.replaceInText(__("financial_calc_text1")) }}
      </p>
    </div>
  </div>
</template>

<script>
import vueSlider from "vue-slider-component";
export default {
  props: ["totalPrice", "financialCalculator"],
  components: {
    vueSlider,
  },
  name: "FinancialCalculator",
  data() {
    return {
      downpayment: this.financialCalculator.defaultDownpayment(),
      period: this.financialCalculator.defaultFinancingTime(),
      remaining: this.financialCalculator.defaultRemainingValue(),
    };
  },
  watch: {
    downpayment() {
      this.updateFinancialCalculatorService();
    },
    period() {
      this.updateFinancialCalculatorService();
    },
    remaining() {
      this.updateFinancialCalculatorService();
    },
  },
  mounted() {
    this.updateFinancialCalculatorService();
  },
  computed: {
    display() {
      return {
        downpayment: Math.round((this.downpayment * this.totalPrice) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        downpaymentMin: ((this.totalPrice * this.financialCalculator.minDownpayment()) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        downpaymentMax: ((this.totalPrice * this.financialCalculator.maxDownpayment()) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        period: this.period,
        periodMin: this.financialCalculator.financingTimes()[0],
        periodMax: this.financialCalculator.financingTimes()[this.financialCalculator.financingTimes().length - 1],
        remaining: Math.round((this.remaining * this.totalPrice) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        remainingMin: ((this.totalPrice * this.financialCalculator.minRemainingValue()) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        remainingMax: ((this.totalPrice * this.financialCalculator.maxRemainingValue()) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        estimatedMonthly: Math.round(this.financialCalculator.loanValue())
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
      };
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateFinancialCalculatorService() {
      this.financialCalculator.setParameters(this.totalPrice, this.downpayment, this.period, this.remaining);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./scss/media/general/financialcalculator.scss";
@import "./scss/media/1440/financialcalculator.scss";
@import "./scss/media/1280/financialcalculator.scss";
@import "./scss/media/1024/financialcalculator.scss";
@import "./scss/media/850/financialcalculator.scss";
@import "./scss/media/550/financialcalculator.scss";
@import "./scss/media/380/financialcalculator.scss";
</style>